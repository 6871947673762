<template>
    <div>
        <div
            class="flex items-center px-5 py-3 mt-5 bg-white rounded-lg shadow-xl"
        >
            <el-collapse class="flex-1">
                <el-collapse-item>
                    <template slot="title">
                        <div
                            class="flex items-center w-full px-4 cursor-pointer"
                            @click="toggleLogs = !toggleLogs"
                        >
                            <div class="flex items-center flex-1">
                                <h2 class="text-base">
                                    狀態：{{ order.status | accountStatus }}
                                </h2>
                                <i
                                    :class="
                                        toggleLogs
                                            ? 'transform rotate-90 '
                                            : 'transform rotate-0 '
                                    "
                                    class="ml-2 transition duration-200 ease-linear ni ni-bold-right"
                                ></i>
                            </div>
                            <button
                                class="text-xs leading-none orange-btn-800 md:text-sm"
                                @click.stop="toggleMarkError"
                            >
                                標示異常
                            </button>
                        </div>
                    </template>
                    <div v-if="order.log.length > 0">
                        <div
                            v-for="log in order.log.slice().reverse()"
                            :key="log.time"
                            class="pb-2 mb-2"
                        >
                            <div class="flex items-center">
                                <div
                                    class="text-sm text-gray-400"
                                    style="width: 80px"
                                >
                                    {{
                                        log.status | accountStatusText
                                    }}&nbsp;:&nbsp;
                                </div>
                                <span class="text-black">{{
                                    log.time | formatDateTime
                                }}</span>
                                <span
                                    v-if="log.user_name"
                                    class="ml-2 text-black"
                                    >| {{ log.user_name }}</span
                                >
                                <span v-else class="ml-2 text-black"
                                    >| {{ log.user_id }}(操作者id)</span
                                >
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!-- 標示異常區塊 -->
        <div v-if="hasMarkError" class="mt-5 bg-white rounded-lg">
            <div class="mx-auto px-[16%] py-16">
                <div class="text-lg font-bold">標示異常</div>

                <el-form
                    ref="formRefDom"
                    class="px-1"
                    :model="form"
                    :rules="rules"
                >
                    <el-form-item prop="status" class="mt-5">
                        <label class="block mb-1 text-sm text-gray-400"
                            >修改狀態</label
                        >
                        <el-select v-model="form.status" placeholder="請選擇">
                            <el-option
                                v-for="item in statusOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="description">
                        <label class="block mb-1 text-sm text-gray-400"
                            >異常描述</label
                        >
                        <el-input
                            v-model="form.description"
                            type="textarea"
                            placeholder="請輸入內容"
                        />
                    </el-form-item>
                </el-form>
                <div class="flex justify-center">
                    <button
                        class="mt-5 orange-btn-800-lg text-md"
                        @click.prevent="onSubmit"
                    >
                        更新異常資訊
                    </button>
                </div>
            </div>
        </div>
        <div class="p-5 mt-5 bg-white rounded-lg shadow-xl">
            <div class="max-w-[1000px] 2xl:mx-auto lg:mx-20">
                <h3 class="text-xl font-bold">代付款項資訊</h3>
                <div class="flex mt-2">
                    <div class="flex-1">
                        <p class="mt-2 text-sm text-gray-300">
                            提領人：<span class="text-black">{{
                                order.promoter.user.name
                            }}</span>
                        </p>
                        <p class="mt-2 text-sm text-gray-300">
                            提領金額：<span class="text-black"
                                >${{ order.commission | formatCurrency }}</span
                            >
                        </p>
                    </div>
                    <div class="flex-1 pl-5 border-l border-gray-100">
                        <p class="mt-2 text-sm text-gray-300">
                            申請時間：<span class="text-black">{{
                                order.created_at | formatDateTime
                            }}</span>
                        </p>
                        <p class="mt-2 text-sm text-gray-300">
                            匯出TXT：<span class="text-black">{{
                                order.export_time | formatDateTime
                            }}</span>
                        </p>
                        <p class="mt-2 text-sm text-gray-300">
                            預計撥款：<span class="text-black">{{
                                order.transaction_date | formatDateTime
                            }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="mx-10 my-5 border-t border-gray-100"></div>
            <div class="max-w-[1000px] 2xl:mx-auto lg:mx-20">
                <h3 class="text-xl font-bold">帳戶資訊</h3>
                <div class="flex mt-2">
                    <div class="flex-1">
                        <p class="mt-2 text-sm text-gray-300">
                            銀行代碼：<span class="text-black">{{
                                order.banking.bankCode
                            }}</span>
                        </p>
                        <p class="mt-2 text-sm text-gray-300">
                            銀行戶名：<span class="text-black">{{
                                order.banking.accountName
                            }}</span>
                        </p>
                    </div>
                    <div class="flex-1 pl-5 border-l border-gray-100">
                        <p class="mt-2 text-sm text-gray-300">
                            通匯代碼：<span class="text-black">{{
                                order.banking.swift
                            }}</span>
                        </p>
                        <p class="mt-2 text-sm text-gray-300">
                            銀行帳號：<span class="text-black">{{
                                order.banking.accountId
                            }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="flex justify-center mt-10 mb-5">
                <button
                    class="px-5 py-2 text-black transition duration-200 border border-black rounded-lg hover:bg-black hover:text-white"
                    @click.prevent="
                        router.push({
                            name: 'salary_withdraw_salesman',
                            params: {
                                status:
                                    order.status === 2 || order.status === 1
                                        ? 0
                                        : order.status,
                            },
                        })
                    "
                >
                    返回記錄列表
                </button>
            </div>
        </div>
    </div>
</template>

<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount } from "@vue/composition-api";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 導入 filter 方法
import "../filterAccount";
import { isEmpty } from "@/service/anyService";
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const router = useRouter();
  const {
    proxy
  } = getCurrentInstance();
  // 表單 dom
  const formRefDom = ref(null);
  // 表單資料
  const form = ref({});
  // 表單驗證規則
  const rules = computed(() => {
    if (form.value.status === -1) {
      return {
        description: [{
          required: true,
          message: "請輸入異常原因",
          trigger: "blur"
        }],
        status: [{
          required: true,
          message: "請選擇異常狀態",
          trigger: "blur"
        }]
      };
    }
    return {
      status: [{
        required: true,
        message: "請選擇異常狀態",
        trigger: "blur"
      }]
    };
  });
  // 訂單資料
  const order = ref({
    banking: {},
    promoter: {
      user: {}
    },
    log: []
  });
  // 訂單狀態選擇
  const statusOptions = ref([{
    label: "撥款處理",
    value: 1
  }, {
    label: "已匯出TXT",
    value: 2
  }, {
    label: "撥款完成",
    value: 3
  }, {
    label: "標示異常",
    value: -1
  }]);
  // 判斷是否顯示紀錄
  const toggleLogs = ref(false);
  // 標記異常顯示輸入表單
  const hasMarkError = ref(false);
  /**
   * 標記異常
   */
  function toggleMarkError() {
    hasMarkError.value = !hasMarkError.value;
  }

  /**
   * 取得資料
   */
  async function getData() {
    try {
      const {
        data
      } = await proxy.$api.GetSalemanSalaryWithdrawRecordApi(route.params.id);
      order.value = data;
      // 判斷 details 時 代表此款項有異常情況 要出現 標示異常表單
      if (data.status === -1) {
        hasMarkError.value = true;
        form.value = {
          status: data.status,
          assignee: data.details.assignee,
          description: data.details.description
        };
      }
    } catch (err) {
      console.log(err);
      proxy.$message({
        type: "error",
        message: "取得介紹人代付款項資料失敗"
      });
    }
  }

  /**
   * 表單發送
   */
  async function onSubmit() {
    try {
      // 等待表單驗證是否成功 try catch 會自動判斷是 true 或 false 因次不用寫 if 判斷
      await formRefDom.value.validate();
      await updateStatus();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "表單驗證失敗"
      });
    }
  }
  /**
   * 更新狀態
   */
  async function updateStatus() {
    try {
      await proxy.$api.UpdateSalemanSalaryWithdrawRecordApi(route.params.id, form.value);
      await getData();
      proxy.$message({
        type: "success",
        message: "更新成功"
      });
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "更新失敗"
      });
    }
  }
  /**
   * 顯示名稱
   * @param { type String } type 型別
   */
  function showUserName(type) {
    switch (type) {
      case "PROVIDER":
        return user.name;
      case "ADMIN":
        return "管理者";
    }
  }
  onMounted(async () => {
    form.value = {};
    hasMarkError.value = false;
    await getData();
  });
  onActivated(async () => {
    form.value = {};
    hasMarkError.value = false;
    await getData();
  });
  return {
    router,
    formRefDom,
    form,
    rules,
    order,
    statusOptions,
    toggleLogs,
    hasMarkError,
    toggleMarkError,
    onSubmit
  };
};
export default __sfc_main;
</script>

<style scoped lang="scss">
::v-deep {
    .el-collapse {
        border: none;
    }
    .el-collapse-item__header {
        border: none;
    }
    .el-collapse-item__arrow {
        display: none;
    }
    .el-collapse-item__wrap {
        border: none;
    }
}
</style>
